.imgFluid {
  max-width: 100%;
  height: auto;
  display: inline-block;
}

.eventImgFluid {
  max-width: 700px;
  margin-top: 2rem;
  width: 100%;
}
