.refact-fb-embed-block {
  display: flex;
  justify-content: center;
  margin: auto;
  max-width: 750px;
}
.fb-post {
  margin-top: 20px;
  margin-bottom: 20px;
  justify-content: center;
}

.fb-post > span:first-child {
  margin: 0 auto;
}

.fb-desktop-only {
  display: flex !important;
}

.fb-mobile-only {
  display: none;
}

@media (max-width: 768px) {
  .fb-desktop-only {
    display: none !important;
  }
  .fb-mobile-only {
    display: block;
  }
}
