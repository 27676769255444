.Listen_music {
  list-style: none;
  padding: 0;
  display: inline-block;
  background: #080d17bf;
  border-radius: 12px;
  border: 1px solid #ffffff3b;
  backdrop-filter: blur(20px);
  padding: 16px;
  position: fixed;
  right: 38px;
  top: 101px;
  z-index: 9999;
  transition: ALL 0.3s ease-in;

  @media only screen and (min-width: 391px) and (max-width: 575px) {
    right: 9px;
  }

  @media only screen and (max-width: 390px) {
    border: 0px solid rgba(255, 255, 255, 0.231372549);
    padding: 12px;
    position: fixed;
    right: 11px;
    top: 84px;
    left: 11px;
  }

  @media only screen and (max-width: 360px) {
    padding: 10px;
  }

  & ul {
    list-style: none;
    padding-left: 0px;
  }

  & .link {
    transition: all 0.4s ease;
    display: flex;
    padding-bottom: 16px;

    @media only screen and (max-width: 390px) {
      position: relative;
    }

    @media only screen and (max-width: 390px) {
      padding-bottom: 0px;
    }

    & .Listen_music_image {
      margin-right: 12px;

      & img {
        max-width: 56px;
        max-height: 56px;
        display: flex;
        align-items: center;
        justify-content: center;

        @media only screen and (max-width: 360px) {
          max-height: 40px;
          max-width: 40px;
        }
      }
    }

    & .listen_music_content {
      text-align: left;
    }

    & h2 {
      font-size: 16px;
      font-weight: 500;
      line-height: 22px;
      color: #fff;
      margin-bottom: 0;
      display: block;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      height: 22px;
      max-width: 163px;
      margin-top: 0px;

      @media only screen and (min-width: 391px) and (max-width: 575px) {
        max-width: 130px;
      }

      @media only screen and (max-width: 390px) {
        font-size: 14px;
        max-width: 130px;
      }

      @media only screen and (max-width: 360px) {
        font-size: 12px;
      }
    }

    & p {
      margin-bottom: 0;
      margin-top: 0px;
    }

    & .by_author {
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      color: #cccccc;
      margin-bottom: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media only screen and (max-width: 390px) {
        font-size: 10px
      }

      & span:last-child {
        margin-left: 4px;
      }

      & .author_name {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 66px;

        @media only screen and (max-width: 390px) {
          margin-left: 0px;
        }
      }

      & img {
        max-width: 11px;
        aspect-ratio: 11 / 15;
        margin-right: 10px;
      }
    }

    & .expand_more {
      margin-left: 8px;
      cursor: pointer;
    }
  }

  & .status {
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    color: #e63838;

    & .btn-default {
      background: none;
    }
  }

  & .action_button {
    margin-left: 15px;
    display: flex;
    align-items: center;

    @media only screen and (max-width: 390px) {
      position: absolute;
      right: 0px;
      align-self: anchor-center;
    }

    & button {
      background-color: transparent;
      border: 0;
      margin-right: 8px;
      cursor: pointer;

      @media only screen and (max-width: 390px) {
        margin-right: 3px;
      }
    }

    & .flex_property.play_icon {
      display: flex;

      @media only screen and (max-width: 390px) {
        flex-wrap: nowrap;
      }
    }

    & .play_icon>button {
      width: 20px;
    }

    & .status {
      width: 48px;

      @media only screen and (max-width: 390px) {
        width: 34px;
      }

      @media only screen and (max-width: 360px) {
        width: 26px;
      }

    }

  }



  & .status button {
    width: 40px;
    height: 40px;
    display: INLINE-flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    border-radius: 100px;
    cursor: pointer;
    border: 0px;

    @media only screen and (max-width: 390px) {
      width: 32px;
      height: 32px;
    }

    @media only screen and (max-width: 360px) {
      width: 25px;
      height: 25px;
    }

    & svg {
      width: 13px;
      height: 12px;
      color: #000;
      fill: #000 !important;
    }

    & div {
      display: flex;
    }
  }

  div#sync-banner {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }

  & .bottom_most {
    margin-left: 0;
    padding: 15px 0 0;
    border-top: 1px solid #525967cc;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 16px;

    @media only screen and (max-width: 390px) {
      display: none;
    }
  }

  .submenu {
    height: 0;
    opacity: 0;
    overflow: hidden;
    transition: height 0.5s ease, opacity 0.5s ease;
    margin: 0;

    &.open {
      opacity: 1;
    }

    & li {
      text-align: left;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 15px;
      font-size: 16px;

      &:first-child {
        border-top: 1px solid #525967cc;
        padding-top: 15px;

        @media only screen and (max-width: 390px) {
          margin-top: 15px;
        }
      }

      & button {
        align-items: center;
        display: flex;
        font-weight: 500;
        line-height: 22px;
        color: #fff;
        margin-bottom: 0;
        padding: 0px;
        font-size: 16px;

        @media only screen and (max-width: 360px) {
          font-size: 14px;
        }

        &.play-btn {
          @media only screen and (max-width: 390px) {
            text-align: left;
            margin-right: 5px;
          }
        }

        & span {
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 250px;
          text-align: left;
        }

        & img {
          margin-left: 0px;
          margin-right: 15px;
        }
      }

      & .status {
        & button {
          width: 21px;
          height: 21px;

          & svg {
            width: 11px;
            height: 9px;
          }
        }
      }
    }
  }

  &.active {
    & .bottom_most {
      border-bottom: 1px solid #525967cc;
      padding: 15px 0px 15px;
    }

    & .submenu {
      display: block;
    }
  }

  & .pulsating-circle {
    position: relative;
    width: 8px;
    height: 8px;
    display: inline-block;
    margin-right: 8px;

    &:before {
      content: "";
      position: absolute;
      display: block;
      width: 18px;
      height: 18px;
      top: -5px;
      right: -5px;
      box-sizing: border-box;
      border-radius: 50%;
      background-color: #e63838;
      transition: all 0.3s ease-in;
      animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
    }

    &:after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      display: block;
      width: 100%;
      height: 100%;
      background-color: #e63838;
      border-radius: 50%;
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
    }
  }

  & .stream-cue-point-data {
    width: 170px;
    max-width: 170px;
    overflow: hidden;
    white-space: nowrap;
    position: relative;
    display: flex;
    align-items: center;
    margin-top: 3px;

    @media only screen and (max-width: 575px) {
      max-width: 130px;
      width: 130px;
    }

    & .scroll-text {
      display: inline-block;
      transform: translateX(0); // Static by default
      // animation: marquee 8s linear infinite;
      color: #fff;
      font-size: 12px;
      line-height: 1.2;
      font-weight: 400;

      &.animate {
        animation: marquee 8s linear infinite;
      }
    }

    @keyframes marquee {
      0% {
        transform: translateX(0);
      }

      // Start at normal position
      20% {
        transform: translateX(0);
      }

      // Pause briefly
      100% {
        transform: translateX(-100%);
      }

      // Move out to the left
    }
  }

  .opscoad-Web_Player {
    @media only screen and (max-width: 390px) {
      overflow: scroll;
      margin-top: 16px;
    }

    & iframe {
      @media only screen and (max-width: 390px) {
        width: 100%;
      }
    }
  }
}

header {
  // @media (max-width: 575px) {
    padding-right: 0px !important;
  // }
}

@keyframes pulse-ring {
  0% {
    transform: scale(0.7);
  }

  80%,
  100% {
    opacity: 0;
  }
}

.status .play-btn,
.status .pause-btn,
.status .resume-btn,
.status .loading-btn {
  display: none !important;
  background-color: rgb(255 255 255) !important;
}

.Listen_music .link .flex_property {
  display: flex;
  align-items: center;
  justify-content: center;
}

.LIVE_PAUSE .resume-btn,
.LIVE_PLAYING .pause-btn,
.LIVE_STOP .play-btn,
.LIVE_FAILED .play-btn,
.LIVE_BUFFERING .loading-btn,
.LIVE_CONNECTING .loading-btn,
.LIVE_RECONNECTING .loading-btn,
.STREAM_GEO_BLOCKED .play-btn,
.STATION_NOT_FOUND .play-btn {
  align-items: center;
  background-color: red;
  background-color: #ba9951;
  border: 0;
  cursor: pointer;
  display: flex !important;
  height: 54px;
  justify-content: center;
  width: 54px;
}



.loading {
  color: #000000 !important;
  min-height: 1em;
  min-width: 1em;
  pointer-events: none;
  position: relative;
}

.loading::after {
  animation: autoload-loading 500ms infinite linear;
  border: 0.1em solid #000000;
  border-radius: 50%;
  border-right-color: transparent;
  border-top-color: transparent;
  content: "";
  display: block;
  height: 1em;
  left: 50%;
  margin-left: -0.5em;
  margin-top: -0.5em;
  position: absolute;
  top: 50%;
  width: 1em;
  z-index: 1;
}

@keyframes autoload-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media (min-width: 992px) and (max-width: 1375px) {
  .container_wrap {
    max-width: 960px;
    margin: 0px auto;
  }
}

@media (max-width: 991px) {
  .container_wrap {
    max-width: 100%;
    margin: 0 40px;
    position: relative;
    padding-bottom: 40px;
  }
}

@media (max-width: 575px) {
  .container_wrap {
    margin: 0 24px;
  }
}

img {
  height: auto;
  max-width: 100%;
}

.container_wrap {
  max-width: 1280px;
  margin: 0 auto;

  @media only screen and (min-width: 992px) and (max-width: 1375px) {
    max-width: 960px;
  }

  @media only screen and (max-width: 991px) {
    max-width: 100%;
    margin: 0 40px;
    position: relative;
    padding-bottom: 40px;
  }

  @media only screen and (max-width: 575px) {
    margin: 0 24px;
  }
}


.podcast_div {
  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    margin-right: 0px;
  }

  ::-webkit-scrollbar-track {
    background-color: #E8E8E8;
    -webkit-border-radius: 8px;
    border-radius: 8px;

  }

  ::-webkit-scrollbar-thumb {
    -webkit-border-radius: 8px;
    border-radius: 8px;
    background: #ED3A32;
  }
}

.Listen_music {
  position: fixed;
  right: -427px;
  opacity: 0;
  transition: right 0.5s ease-in-out, opacity 0.5s ease-in-out, visibility 0.5s ease-in-out;
  visibility: hidden;

  &.visible {
    right: 11px;
    transition: right 0.5s ease-in-out;
    opacity: 1;
    visibility: visible;
    max-width: calc(100% - 22px);
    @media only screen and (max-width: 575px) {
      padding-top: 20px;
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  & .close_icon {
    position: absolute;
    width: 15px;
    right: 4px;
    top: 2px;
    cursor: pointer;
    display: block;
    @media only screen and (max-width: 575px) {
      display: flex;
      right: 5px;
      top: 4px;
    }

    & img {
      width: 20px;
    }
  }
}