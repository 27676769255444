.podcast_div_wrap {
  margin-bottom: 60px;

  @media only screen and (max-width: 991px) {
    margin-bottom: 40px;
  }

  @media only screen and (max-width: 575px) {
    margin-bottom: 24px;
  }

  .podcast_div {
    background: #0f1c24;
    display: flex;

    @media only screen and (max-width: 575px) {
      flex-wrap: wrap;
    }

    .scroll_div {
      max-height: 254px;
      overflow-y: auto;

      @media only screen and (max-width: 991px) {
        max-height: 556px;
      }

      @media only screen and (max-width: 575px) {
        max-height: 262px;
      }
    }

    .podcast_left_box {
      display: flex;
      background: #080d17;
      border-right: 1px solid #313335;
      max-width: 64%;
      width: 100%;

      @media only screen and (max-width: 991px) {
        flex-wrap: wrap;
        max-width: 50%;
      }

      @media only screen and (max-width: 575px) {
        max-width: 100%;
      }

      .content_wrap {
        padding: 30px 30px;
        max-width: 65%;
        width: 100%;

        @media only screen and (max-width: 991px) {
          padding: 24px;
          max-width: 100%;
        }

        h3 {
          font-size: 24px;
          font-weight: 500;
          line-height: 32px;
          color: #fff;
          margin-bottom: 12px;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          height: 64px;
          width: 100%;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;

          @media only screen and (max-width: 575px) {
            font-size: 16px;
            line-height: 24px;
            height: 52px;
          }
        }

        span {
          font-size: 16px;
          font-weight: 400;
          line-height: 18px;
          color: #ccc;
        }
      }

      .img_box {
        max-width: 300px;
        width: 100%;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 300px;
        padding:24px 0px 24px 24px;
        @media only screen and (max-width: 991px) {
          padding: 15px;
          height:auto;
        }

      }

      .music_frame {
        margin-top: 24px;

        .music_icon {
          margin-right: 15px;

          .status {
            button {
              width: 28px;
              height: 37px;
              border-radius: 100px;

              svg {
                width: 14px;
                height: 17px;
              }
            }
          }
        }

        .mr_30 {
          margin-right: 30px;
        }

        .controls-progress {
          display: block;

          span.time {
            display: inline-block;
            font-size: 12px;
            line-height: 12px;
            margin-top: 12px;

            &:last-child {
              float: right;
            }
          }

          .ee-range-input {
            input {
              width: 100%;
            }
          }
        }

        .music_frame_wrap {
          display: flex;
          align-items: center;
          margin-bottom: 15px;
        }

        .play_icon {
          display: flex;
          align-items: center;

          @media only screen and (max-width: 991px) {
            order: 1;
          }
        }

        .sound_icon {
          margin-top: 5px;
          position: relative;

          @media only screen and (max-width: 991px) {
            order: 3;
            margin-right: 0px;
            margin-top: 4px;
          }

          .ee-range-input {
            position: absolute;
            top: 0;
            left: 112%;
            input{
              @media only screen and (max-width: 390px) {
               width:90px;
              }
            }
            
          }

          .ee-range-input-slider {
            visibility: hidden;

            .pre-bar {
              background: yellow;
            }

            &.-volume-visible {
              visibility: visible;
            }
          }
        }       
      }
    }

    .podcast_right_box {
      padding: 24px 24px 0px 28px;
      max-width: 36%;
      min-width: 36%;
      width: 100%;

      @media only screen and (--max-fl-xlarge-viewport) {
        min-width: unset;
      }

      @media only screen and (max-width: 991px) {
        max-width: 50%;
        padding: 24px;
        min-width: unset;
      }

      @media only screen and (max-width: 575px) {
        max-width: 100%;
      }

      @media only screen and (max-width: 360px) {
        padding: 15px;
      }

      .podcast_box {
        display: flex;
        margin-bottom: 12px;

        .pod_img {
          max-width: 56px;
          min-width: 56px;
          margin-right: 12px;
          position: relative;
          max-height: 56px;

          img {
            max-height: 56px;
            height: 56px;
            object-fit: cover;
          }

          .status {
            position: absolute;
            left: 0px;
            right: 0px;
            top: 0px;
            bottom: 0px;
            margin: auto;
            width: 25px;
            height: 25px;

            button {
              height: 25px;
              width: 15px;
              border-radius: 100px;

              > div {
                display: flex;
                align-items: center;
                justify-content: center;
              }

              svg {
                width: 10px;
                height: 13px;
              }
            }
          }
        }

        .pod_content {
          a {
            font-size: 16px;
            font-weight: 500;
            line-height: 22px;
            text-decoration: none;
            color: #fff;
            margin-bottom: 4px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 272px;
            display: block;

            @media only screen and (--max-fl-x2large-viewport) {
              max-width: 190px;
            }

            @media only screen and (max-width: 991px) {
              max-width: 215px;
            }

            @media only screen and (max-width: 575px) {
              max-width: 170px;
            }
          }

          span {
            font-size: 12px;
            font-weight: 400;
            line-height: 18px;
            color: #ccc;
          }
        }
      }
    }

    &::-webkit-scrollbar-track {
      background-color: #313335;
    }
  }
}


 /* css for gampreroll */
  /* Define Global Variables */
  $global-black: #000;
  $global-white: #fff;
  $global-theme-secondary: #333; // Customize this as needed
  
  /* Preroll Wrapper */
  .preroll-wrapper {
    background-color: rgba(0, 0, 0, 0.75);
    display: none;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10000;
  
    &.-active {
      display: block;
    }
  }

  /* Gampreroll Wrapper */
  .gampreroll-wrapper {
    display: none;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10000;
  
    &.-active {
      display: block;
    }
  }
  
  /* Beasley Vimeo fullscreen style */
  .beasley-vimeo:fullscreen {
    background-color: $global-black;
  
    iframe {
      height: 100%;
      width: 100%;
    }
  }
  
  /* Shade background */
  .gampreroll-shade {
    background-color: rgba(0, 0, 0, 0.75);
  }
  
  /* Preroll Container (for video aspect ratio) */
  .preroll-container {
    height: 0;
    max-width: 100%;
    overflow: hidden;
    padding-bottom: 56.25%; // 16:9 aspect ratio
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
  
  /* Preroll Player styling */
  .preroll-player {
    background-color: $global-black;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  
    @media (min-width: 768px) {
      min-width: 640px;
    }
  
    @media (max-width: 767px) {
      width: 92%;
    }
  }
  
  /* Gam Preroll Player */
  .gam-preroll-player {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0%);
  
    @media (min-width: 768px) {
      min-width: 640px;
      width: 46%;
    }
  
    @media (max-width: 767px) {
      width: 92%;
    }
  }
  
  /* Preroll Notification */
  .preroll-notification {
    background-color: $global-black;
    color: $global-white;
    padding: 0.15rem 0;
    text-align: center;
  }
  
  /* Sponsor Controls */
  .controls-sponsor {
    min-width: 150px;
  }
  
  /* Content Wrap */
  .content-wrap {
    .placeholder-dfp {
      margin-bottom: 1.775rem;
      padding-bottom: 1.775rem;
    }
  }
  
  /* Mobile Sponsor Styling */
  .sponsor-mobile {
    background-color: $global-theme-secondary;
    box-shadow: inset 0px -2px 2px rgba(0, 0, 0, 0.2), inset 0px 2px 0px rgba(255, 255, 255, 0.25);
    display: flex;
    justify-content: center;
    padding: 0.5rem 0.5rem 0.875rem 0.5rem;
  }
  
  /* Desktop Sponsor Styling */
  .sponsor-desktop {
    background-color: $global-theme-secondary;
    box-shadow: inset 0px -2px 2px rgba(0, 0, 0, 0.2), inset 0px 2px 0px rgba(255, 255, 255, 0.25);
    display: flex;
    justify-content: right;
    padding: 0.5rem 0.5rem 0.875rem 0.5rem;
  }