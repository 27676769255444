@media (max-width: 500px) {
  .newsletter-sticky.newsletter-sticky-v2 {
    width: 100% !important;
    max-width: 90%;
    left: 5%;
    right: 0 !important;
    margin: 0 auto;
  }
}

main[data-og-content-type='article'][data-og-template='default']
  div[data-id='page-content']
  a,
main[data-og-content-type='page'][data-og-template='default']
  div[data-id='page-content']
  a {
  overflow-wrap: break-word;
  max-width: 100%;
  display: inline-block;
  color: #0066cc;
  text-decoration: none;
  transition: all 0.1s ease-in-out;
}

main[data-og-content-type='article'][data-og-template='default']
  div[data-id='page-content']
  a:hover,
main[data-og-content-type='page'][data-og-template='default']
  div[data-id='page-content']
  a:hover {
  text-decoration: underline !important;
  opacity: 0.85;
}

main[data-og-content-type='article'][data-og-template='default']
  div[data-id='page-content']
  p {
  word-wrap: break-word;
}
