.footer-container{
    background-color: #1a1a1a;
    color: rgb(247, 249, 242);
    padding: 2.7222rem 2.222rem;
    margin-top: 4.611rem;

    .sub-title {
        font-weight: 700 !important;
        line-height: 2;
        font-size: 1rem !important;
        margin-top: 0;
        margin-bottom: .278rem;
        font-family: inherit !important;
    }

    .appIcons img {
        margin-bottom: .778rem;
        text-decoration: none !important;
    }

    p {
        line-height: 1.4;
        font-size: .889rem;
        margin: 0 0 2em;
    }

    ul {
        padding: 0 !important;
    }

    .newsletter .btn {
        min-width: 100%;
        background-color: transparent;
        border: 1px solid #ea252a !important;
        color: #ea252a !important;
        border-radius: 4px;
        cursor: pointer;
        display: block;
        font-size: .889rem;
        font-weight: 600;
        padding: .722rem 1.222rem;
        text-align: center;
    }

    .download, .newsletter, .about, .connect {
        margin-bottom: 1rem;

        a {
            text-decoration: none;
            font-weight: 500;

            &:hover {
                text-decoration: underline;
            }
        }

        li {
            padding: 0;
            font-size: .889rem;

            &:not(:last-child) {
                padding-bottom: .889rem;
            }
        }
    }

    .social {
        display: flex;
        align-items: center;
        margin-top: 30px;
        width: 50%;

        li:not(:last-child) {
            margin-right: 50px;
            padding-bottom: 0;
        }
    }
}